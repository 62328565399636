// sass-lint:disable-all
// TODO: Make version dynamic
@import '~react-toastify/scss/main';
@import 'foundation';
@import 'grid';

:root {
  @include root-prop(--reysep-version-number, '2.0');
}

// sass-lint:disable-next-line no-ids
#root {
  display: flex;
  min-height: 100%;
}

html,
body,
button {
  font-family: font-family();
}

html,
body {
  height: 100%;
  min-height: 100%;
}

html {
  -webkit-font-smoothing: antialiased;
  // This needs to come after -webkit-font-smoothing
  -moz-osx-font-smoothing: grayscale;
  font-size: ($base-font-size / $default-browser-font-size) * 100%;
  position: relative;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
}

body {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: 1em;
  font-weight: 400;
  margin: 0;
}

a {
  text-decoration: none;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
}
