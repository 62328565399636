@import 'grid/breakpoints';
@import 'grid/system';

.container {
  @include make-container;
  @include make-container-max-widths;
}

.container-fluid {
  @include make-container;
}

.row {
  @include make-row;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;

  > .col,
  > [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
}

@include make-grid-columns;
