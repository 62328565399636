$font-family-data: (
  base: #{'Open Sans',
  sans-serif},
  monospace: #{'Lucida Console',
  monospace}
);

/// Returns the font stack for a given family.
///
/// @param {String} $family - The key for the given family.
/// @return {Number} The font stack for the family.

@function font-family($family: base) {
  $fetched-value: map-get($font-family-data, $family);

  @if $fetched-value != null {
    @return $fetched-value;
  }

  @else {
    @error 'Font family `#{$family}` not found. Available font families: #{available-names($font-family-data)}';
  }
}

//size
$fz14: 14px;
$fz18: 18px;
$fz20: 20px;

// weight
$fw-light: 100;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;
