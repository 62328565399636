$blue-primary: #375093;
$blue-secondary: #2e4279;
$gray-disabled: #dee5e5;
$gray-text: #576271;
$gray-background: #f6f6f6;
$red-warning: #f76366;
$green-success: #80ba45;
$green-info: #1eb9bc;

//gray
$gray1: #e6ebeb;
$gray2: #d1d0d0;
$gray3: #f7f7f7;
$gray4: #bebebe;
$gray5: #f2f5f5;
$gray6: #f2f2f2;
$green1: #08ad50;
$green2: #048b3f;

$spinner: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

$shadow-dropdown: 0 6px 17px 6px rgba(156, 156, 156, 0.14);

$box-shadow: 0 3px 6px 0 $gray4;
$box-shadow2: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
$shadow-dropdown: 0 6px 17px 6px rgba(156, 156, 156, 0.14);
