// helpers
// paddings and margins
$items: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 17, 19, 20, 21;

@each $item in $items {
  .h-m--#{$item} {
    margin: 10px * $item;
  }

  .h-mb--#{$item} {
    margin-bottom: 10px * $item;
  }

  .h-ml--#{$item} {
    margin-left: 10px * $item;
  }

  .h-mr--#{$item} {
    margin-right: 10px * $item;
  }

  .h-mt--#{$item} {
    margin-top: 10px * $item;
  }

  .h-p--#{$item} {
    padding: 10px * $item;
  }

  .h-pb--#{$item} {
    padding-bottom: 10px * $item;
  }

  .h-pl--#{$item} {
    padding-left: 10px * $item;
  }

  .h-pr--#{$item} {
    padding-right: 10px * $item;
  }

  .h-pt--#{$item} {
    padding-top: 10px * $item;
  }

  .h-pv--#{$item} {
    padding-bottom: 10px * $item;
    padding-top: 10px * $item;
  }

  .h-ph--#{$item} {
    padding-left: 10px * $item;
    padding-right: 10px * $item;
  }

  .h-mv--#{$item} {
    margin-bottom: 10px * $item;
    margin-top: 10px * $item;
  }

  .h-mh--#{$item} {
    margin-left: 10px * $item;
    margin-right: 10px * $item;
  }
}

// font-size
.h-fz {
  &--14 {
    font-size: $fz14;
  }

  &--18 {
    font-size: $fz18;
  }

  &--20 {
    font-size: $fz20;
  }
}

//font weight
.h-fw {
  &--light {
    font-weight: $fw-light;
  }

  &--regular {
    font-weight: $fw-regular;
  }

  &--medium {
    font-weight: $fw-medium;
  }

  &--bold {
    font-weight: $fw-bold;
  }
}

// flex
.h-align-items {
  &--center {
    align-items: center;
  }
}

.h-flex {
  &--center-v {
    align-items: center;
    display: flex;
  }
}

// bordes
.h-border {
  &--top {
    border-top: 1px solid $gray1;
  }
}

// text align
.h-text-align {
  &--right {
    text-align: right;
  }

  &--center {
    text-align: center;
  }
}

// color
.h-color {
  &--gray-text {
    color: $gray-text;
  }
}

// label
.h-label {
  color: $gray-text;
  display: block;
  font-size: 10px;
  font-weight: 300;
}
