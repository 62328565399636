/// Grid system
@mixin make-container($gutter: $grid-gutter-width) {
  margin-left: auto;
  margin-right: auto;
  padding-left: $gutter / 2;
  padding-right: $gutter / 2;
  width: 100%;
}

@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-row($gutter: $grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter / 2;
  margin-right: -$gutter / 2;
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
  padding-left: $gutter / 2;
  padding-right: $gutter / 2;
  position: relative;
  width: 100%;
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage($size / $columns);
  max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;

  margin-left: if($num == 0, 0, percentage($num));
}

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-column {
    padding-left: $gutter / 2;
    padding-right: $gutter / 2;
    position: relative;
    width: 100%;
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }

    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      .col#{$infix}-auto {
        flex: 0 0 auto;
        max-width: 100%; // Reset earlier grid tiers
        width: auto;
      }

      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      .order#{$infix}-first {
        order: -1;
      }

      .order#{$infix}-last {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i} {
          order: $i;
        }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if not($infix == '' and $i == 0) {
          // Avoid emitting useless .offset-0
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }
  }
}

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1368px
);

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

// Grid columns
$grid-columns: 12;
$grid-gutter-width: 30px;
