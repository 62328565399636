$default-browser-font-size: 16px;
$base-font-size: 10px;

/// Returns the value in rem for a given pixel value.
/// @param {Number} $value - The pixel value to be converted.
/// @return {Number} The converted value in rem.

@function rem($value) {
  $unit: unit($value);

  @if $value == 0 {
    @return 0;
  }

  @else if $unit == 'rem' {
    @return $value;
  }

  @else if $unit == 'px' {
    @return $value / $base-font-size * 1rem;
  }

  @else if $unit == 'em' {
    @return $unit / 1em * 1rem;
  }

  @else {
    @error 'Value must be in px, em, or rem.';
  }
}

/// Returns the value in pixels for a given rem value.
/// @param {Number} $value - The rem value to be converted.
/// @return {Number} The converted value in pixels.

@function px($value) {
  $unit: unit($value);

  @if $value == 0 {
    @return 0;
  }

  @else if $unit == 'px' {
    @return $value;
  }

  @else if $unit == 'em' {
    @return ($value / 1em) * $base-font-size;
  }

  @else if $unit == 'rem' {
    @return ($value / 1rem) * $base-font-size;
  }

  @else {
    @error 'Value must be in rem, em, or px.';
  }
}

/// Returns the value in ems for a given pixel value. Note that this
/// only works for elements that have had no font-size changes.
/// @param {Number} $value - The pixel value to be converted.
/// @return {Number} The converted value in ems.

@function em($value) {
  $unit: unit($value);

  @if $value == 0 {
    @return 0;
  }

  @else if $unit == 'em' {
    @return $value;
  }

  @else if $unit == 'rem' {
    @return $value / 1rem * 1em * ($base-font-size / $default-browser-font-size);
  }

  @else if $unit == 'px' {
    @return $value / $default-browser-font-size * 1em;
  }

  @else {
    @error 'Value must be in px, rem, or em.';
  }
}

/// Sets a property to root
/// @param {String} $prop - property
/// @param {String} $value - value
/// @return {Null}

@mixin root-prop($prop: null, $value: null) {
  @if ($prop and $value) {
    #{$prop}: $value;
  }
}
